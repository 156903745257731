import React from 'react'
import { siteMetadata } from '../../gatsby-config'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from 'components/layout'
import Meta from 'components/meta'

const PriceList = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        priceList: contentfulAsset(
          contentful_id: { eq: "6RYk1I3JR9a9wPiZcONm9K" }
        ) {
          description
          file {
            url
          }
        }
      }
    `
  )

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Price List" />
      <div className="flex-row mt-3">
        <div className="d-flex justify-content-center">
          <h1 className="text-primary text-reset">Price List</h1>
        </div>
      </div>
      <div className="flex-row my-3">
        <div className="d-flex justify-content-center ">
          <div className="home-page-section">
            <div className="card-deck">
              <div className="card ">
                <div className="card-body">
                  <h2 className="card-title">PDF</h2>
                  {/* <a href={data.priceList.file.url}>Download</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex-row">
          <table class="table">
            {data.prices.productsAndServices.rows.map((item, key) => (
              <tr key={key}>
                <td>{item.name}</td>
                <td>{item.price}</td>
              </tr>
            ))}
          </table>
        </div> */}
      </div>
    </Layout>
  )
}
export default PriceList